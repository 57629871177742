import React from 'react'
import { Routes } from './routes'
import Container from 'react-bootstrap/Container'
import { LigueJa } from './LigueJa'
import { NavigationBar } from './NavigationBar'
import { Footer } from './Footer'
import styled from 'styled-components'

const Fixed = styled.div`
  position: fixed;
  top: 0px;
  width: 100%;
  background-color: white;
  z-index: 1;
`

const BoxWithTopSpace = styled.div`
  padding-top: 120px;
`

const AppConfig = styled.div`
  overflow-x: hidden;
`
export const App = () => {

  return (
      <AppConfig>
        <Fixed>
          <Container>
            <LigueJa/>
            <NavigationBar/>
          </Container>
        </Fixed>
        <BoxWithTopSpace>
          <Routes />
          <Footer/>
        </BoxWithTopSpace>
      </AppConfig>
  )
}
