import React, {useState} from 'react'
import {useParams} from 'react-router-dom'
import {fetchImovel} from "../functions/fetchAPI"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Carousel from 'react-bootstrap/Carousel'
import styled from 'styled-components'
import HotelIcon from '@material-ui/icons/Hotel'
import CropFreeSharpIcon from '@material-ui/icons/CropFreeSharp'
import DriveEtaIcon from '@material-ui/icons/DriveEta'
import BathtubIcon from '@material-ui/icons/Bathtub'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'

const Imagem = styled.img`
    object-fit: contain;
    max-height: 500px;
`

const QuestrialSpan = styled.span`
    font-family: questrial, sans-serif;
`

export const Imovel = () => {
    let {id} = useParams()
    const [index, setIndex] = useState(0)
    const [imovel, setImovel] = useState({imagens:[]})

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
      };

    React.useEffect(()=>{
        fetchImovel(setImovel, imovel, id)
    },[])

    return (
        <>
            <Row className="m-3">
                <Col lg="7">
                    <Carousel prevIcon={<DoubleArrowIcon style={{"color":"#7F161A","transform": "scaleX(-1) scale(1.5)"}}/>}
                              nextIcon={<DoubleArrowIcon style={{"color":"#7F161A","transform":"scale(1.5)"}}/>} activeIndex={index} onSelect={handleSelect}>
                        {imovel.imagens.map(imagem => {
                            return(
                            <Carousel.Item key={imagem.id}>
                            <Imagem
                            className="d-block w-100"
                            src={imagem.imagem}
                            />
                        </Carousel.Item>)
                        })}
                    </Carousel>
                </Col>
                <Col lg="5">
                    <h2 className="font-weight-bold text-center mb-5 mt-3">{imovel.nome}</h2>
                    <p className="mx-5 text-justify">{imovel.descricao}</p>
                    <Row className="pb-2 mt-5">
                        <Col className="px-1 text-center">
                            <span><HotelIcon style={{"color":"#7F161A"}}/> <QuestrialSpan>{imovel.quartos}</QuestrialSpan></span>
                        </Col>
                        <Col className="px-1 text-center">
                            <span><BathtubIcon style={{"color":"#7F161A"}}/> <QuestrialSpan>{imovel.banheiros}</QuestrialSpan></span>
                        </Col>
                        <Col className="px-1 text-center">
                            <span><CropFreeSharpIcon style={{"color":"#7F161A"}}/><QuestrialSpan>{imovel.area}m²</QuestrialSpan></span>
                        </Col>
                        <Col className="px-1 text-center">
                            <span><DriveEtaIcon style={{"color":"#7F161A"}}/><QuestrialSpan> {imovel.vagas}</QuestrialSpan></span>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}