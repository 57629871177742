import React, {useState} from 'react'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {fetchImoveis} from "../functions/fetchAPI"
import {CardImovel} from './CardImovel'
import Fade from '@material-ui/core/Fade'
import {useHistory} from 'react-router-dom'


const HomeVendaImoveisArea = styled.div`
    background-color: #E5E5E5;
`

const VendaDeImoveisTitle = styled.h2`
    color: #012942;
    font-family: questrial, sans-serif;
    font-weight: 700;
`

const BotaoVejaMais = styled.button`
    background-color: #7F161A;
    color: white;
    border: 1px solid white;
    font-family: questrial, sans-serif;
    font-weight: 700;
    &:hover{
        color: #7F161A;
        background-color: white;
        border: 1px solid #7F161A;
        transition: all .4s;
    }
`

export const HomeVendaImoveis = () => {
    let history = useHistory()

    const [imoveis, setImoveis] = useState([])
    const [time, setTime] = useState(0)
    const [imoveisAgora, setImoveisAgora] = useState([])

    const mudarImoveis = () => {
        if(imoveis.length < 4){
            setImoveisAgora([0,1,2])
        }else{
            let imovel1,imovel2,imovel3
            imovel1 = Math.floor(Math.random()*imoveis.length)
            do{
                imovel2 = Math.floor(Math.random()*imoveis.length)
            }while(imovel2 === imovel1);
            do{
                imovel3 = Math.floor(Math.random()*imoveis.length)
            }while(imovel3 === imovel1 || imovel3 === imovel2)
            setImoveisAgora([imovel1, imovel2, imovel3])
        }
    }

    React.useEffect(() => {
        fetchImoveis(setImoveis, "venda")
        mudarImoveis()
    }, [])

    React.useEffect(()=>{
        mudarImoveis()
        setTimeout(()=>setTime(time+1),5000)
    },[time])

    return (
        <HomeVendaImoveisArea className="py-5">
            <Container className="text-center">
                <VendaDeImoveisTitle>Venda de Imóveis</VendaDeImoveisTitle>
                <Row>
                {
                    imoveis.map((imovel, index) => {
                        if(imovel.capa && imovel.disponivel_venda && imoveisAgora.includes(index)){
                            return (
                                <Fade key={index} in={imoveisAgora.includes(index)} timeout={500}>
                                    <Col lg="4">
                                        <CardImovel imovel={imovel} tipo="venda"/>
                                    </Col>
                                </Fade>
                            )
                        }
                    })
                } 
                </Row>
                <Row className="mt-3">
                    <Col className="textCenter">
                        <BotaoVejaMais className="rounded py-2 px-3" onClick={()=>history.push("/venda")}>Veja mais</BotaoVejaMais>
                    </Col>
                </Row>
            </Container>
        </HomeVendaImoveisArea>
    )
}