import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import styled from 'styled-components'
import Logo_JZM from '../images/logo_jzm.png'

const Styles = styled.div`
    .navbar{
        background-color: white;
    }

    .navbar-brand, .navbar-nav .nav-link{
        color: #012942;
        display: inline-block;
        font: normal normal normal 21px/1.4em questrial,sans-serif;
        &:hover {
            color: #C2080F;
            transition: 0.5s;
        }
    }
`

const StyledList = styled(List)`
    width: 250px;
`

const Logo = styled.img`
    height: 60px;
`

export const NavigationBar = () => {
    const [state, setState] = React.useState({
        drawerState: false,
    })

    const toogleDrawer = (boolean) => {
        setState({...state, drawerState: boolean})
    }

    return(
        <Styles>
            <Navbar expand="lg">
                <Navbar.Brand href="/"><Logo src={Logo_JZM} alt="JZM logo"/></Navbar.Brand>
                <Navbar.Toggle onClick={() => toogleDrawer(true)}/>
                <Nav className="ml-auto d-none d-lg-block">
                    <Nav.Link href="/" className="pr-4">Home</Nav.Link>
                    |
                    <Nav.Link href="/aluguel" className="pl-4 pr-4">Aluguel</Nav.Link>
                    |
                    <Nav.Link href="/venda" className="pl-4 pr-5">Venda</Nav.Link>
                </Nav>
            </Navbar>
            <Drawer anchor="right" open={state.drawerState} onClose={() => toogleDrawer(false)}>
                <StyledList>
                    <ListItem button component="a" href="/" onClick={() => toogleDrawer(false)}>
                        <ListItemText className="ml-3" primary="Home"/>
                    </ListItem>
                    <ListItem button component="a" href="/aluguel" onClick={() => toogleDrawer(false)}>
                        <ListItemText className="ml-3" primary="Aluguel"/>
                    </ListItem>
                    <ListItem button component="a" href="/VENDA" onClick={() => toogleDrawer(false)}>
                        <ListItemText className="ml-3" primary="Venda"/>
                    </ListItem>
                </StyledList>
            </Drawer>
        </Styles>
    )
}