import React, {useState} from 'react'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {fetchImoveis} from '../functions/fetchAPI'
import {CardImovel} from '../components/CardImovel'

const HomeVendaImoveisArea = styled.div`
    background-color: #E5E5E5;
`

const VendaDeImoveisTitle = styled.h2`
    color: #012942;
    font-family: questrial, sans-serif;
    font-weight: 700;
`
export const Venda = () => {
    const [imoveis, setImoveis] = useState([])

    React.useEffect(() => {
        fetchImoveis(setImoveis, "venda")
    }, [])

    return (
        <HomeVendaImoveisArea className="py-5">
            <Container className="text-center">
                <VendaDeImoveisTitle>Venda de Imóveis</VendaDeImoveisTitle>
                <Row>
                    {
                        imoveis.map((imovel, index) => {
                            if(imovel.capa && imovel.disponivel_venda){
                                console.log(imovel)
                                return (
                                        <Col lg="4" key={imovel.id}>
                                            <CardImovel imovel={imovel} tipo="venda"/>
                                        </Col>
                                )
                            }
                        })
                    } 
                </Row>
            </Container>
        </HomeVendaImoveisArea>
    )
}