export const fetchAPI = async(path, method, data) => {
    const send = {
        method
    }
    if(method === "POST"){
        send.headers = {
            'Content-Type': 'application/json'
        }
        send.body = JSON.stringify(data)
    }
    path = 'https://admin.jzmimoveis.com.br'+path
    return await fetch(path, send).then(response => response.json()).then(data => data)
}

export const fetchImoveis = (setImoveis,tipo) => {
    fetchAPI('/api/imovel/','GET','').then(response=>{
        for(const imovel of response){
            if(imovel.capa){
                if((tipo === "aluguel" && imovel.disponivel_aluguel) || (tipo === "venda" && imovel.disponivel_venda))
                fetchAPI(`/api/imagem-imovel/${imovel.capa}`,'GET','').then(response =>{
                    imovel.capa = response.imagem
                    setImoveis(array => [...array, imovel])
                })
            }
        }
    })
}

export const fetchImovel = (setImovel,imovel,id) => {
    fetchAPI(`/api/imovel/${id}`,'GET','').then(response=>{
        imovel = response
        if(imovel.capa){
            fetchAPI(`/api/imagem-imovel/${imovel.capa}`,'GET','').then(response =>{
                imovel.capa = response.imagem
                setImovel(imovel)
            })
        }
    })
}
