import React from 'react'
import { HomeMainPanel } from '../components/HomeMainPanel'
import { SobreNos } from '../components/SobreNos'
import { HomeAluguelImoveis } from '../components/HomeAluguelImoveis'
import { HomeVendaImoveis } from '../components/HomeVendaImoveis'

export const Home = () => {
    return (
        <>
            <HomeMainPanel />
            <SobreNos/>
            <HomeAluguelImoveis/>
            <HomeVendaImoveis/>
        </>
    )
}