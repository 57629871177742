import React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
    background-color: white;
    color: #7F161A;
    text-align: center;
    font-weight: 700;
    font-size: 0.9em;
    font-family: 'Roboto', sans-serif;
    padding: 10px 0px;
`

export const LigueJa = () => {
    return(
        <StyledDiv>
            LIGUE JÁ! (31) 98899-1005
        </StyledDiv>
    )
}