import React from 'react'
import styled from 'styled-components'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

const SobreNosTitle = styled.h2`
    color: #012942;
    font-family: questrial, sans-serif;
    font-weight: 700;
`

const Text = styled.p`
    color: #5C5C5C;
    font-family: questrial, sans-serif;
    font-size: 1.5em;
`

const StyledRow = styled(Row)`
    margin-top: 100px;
`

export const SobreNos = () => {
    return (
        <Container>
            <StyledRow className="justify-content-center">
                <Col sm="12" lg="8" xl="10" className="text-center">
                    <SobreNosTitle>Sobre Nós</SobreNosTitle>
                    <Text className="mt-5">
                    A JZM Imóveis é uma empresa 100% digital que atua no ramo de locação e venda de casas,
                    apartamentos, lojas, salas, lotes e galpões, na região de Pedro Leopoldo e Belo Horizonte.
                    </Text>
                    <Text classname="mb-5">
                    Na JZM Imóveis você compra ou aluga imóveis com agilidade, segurança e transparência.   
                    </Text>
                </Col>
            </StyledRow>
        </Container>
    )
}