import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Home } from '../screens/Home'
import { Aluguel } from '../screens/Aluguel'
import { Venda } from '../screens/Venda'
import { Imovel } from '../screens/Imovel'

export const Routes = () => {
    return(
        <Router>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/aluguel" component={Aluguel}/>
                <Route path="/venda" component={Venda}/>
                <Route path="/imovel/:id" component={Imovel}/>
            </Switch>
        </Router>
    )
}