import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styled from 'styled-components'
import Logo from '../images/logo_jzm.png'
import IconButton from '@material-ui/core/IconButton'
import Instagram from '@material-ui/icons/Instagram'
import Facebook from '@material-ui/icons/Facebook'
import Phone from '@material-ui/icons/Phone'
import Mail from '@material-ui/icons/Mail'
import Container from 'react-bootstrap/Container'

const FooterLogo = styled.img`
    width: 40%;
    max-width: 100px;
`

const InstagramIcon = styled(Instagram)`
    color: #7F161A;
`

const FacebookIcon = styled(Facebook)`
    color: #7F161A;
`

const PhoneIcon = styled(Phone)`
    color: #7F161A;
`

const MailIcon = styled(Mail)`
    color: #7F161A;
    @media (min-width: 992px) {
        margin-left: 90px;   
    }
`

const StyledIconButton = styled(IconButton)`
    &:hover{
        background-color: #FAFAFA !important;
    }
`

const Text = styled.p`
    font-weight: 500;
    color: #012942;
`

const CenterInsideColumn = styled.div`
    margin-left:auto;
    margin-right:auto;
    display:block;
    text-align: center;
`

export const Footer = () => {
    return(
        <Container>
            <Row className="mt-5">
                <Col lg="6">
                    <CenterInsideColumn>
                        <FooterLogo src={Logo} alt="Footer Logo"/>
                        <StyledIconButton className="ml-3" onClick={()=>window.open("https://instagram.com/jzmimoveis", '_blank')}>
                            <InstagramIcon />
                        </StyledIconButton>
                        <StyledIconButton className="ml-3" onClick={()=>window.open("https://fb.com/jzmimoveis", '_blank')}>
                            <FacebookIcon />
                        </StyledIconButton>
                        <Text>© 2020 Por JZM imóveis</Text>
                    </CenterInsideColumn>
                </Col>
                <Col lg="6" className="mt-3">
                    <CenterInsideColumn>
                        <Text><PhoneIcon className="mr-2"/>(31) 98899 1005</Text>
                        <Text><MailIcon className="mr-2"/>contato@jzmimoveis.com.br</Text>
                    </CenterInsideColumn>
                </Col>
            </Row>
        </Container>
    )
}