import React from 'react'
import styled from 'styled-components'
import PanelImage from '../images/panel.jpg'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

const StyledArea = styled.div`
    height: calc(100vh - 120px);
    width: 100%;
    margin: 0;
    padding: 0;
`

const PanelArea = styled.div`
    height: 80%;
    background-image: url(${PanelImage});
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 991.98px){
        display: none;
    }
`

const RedArea = styled.div`
    height: calc(100vh - 120px);
    position: absolute;
    @media (min-width: 992px){
        width: 450px;
        left: calc(25vw);
        background-color: rgba(127, 22, 26, 0.8);
    }
    @media (max-width: 991.98px){
        width: 100%;
        background-color: rgb(127, 22, 26);
    }
`

const SpacingDiv = styled.div`
    height: 10%;
`

const BigText = styled.p`
    color: white;
    font-size: 5vh;
    margin-top: 12vh;
    font-weight: 600;
`

const MediumText = styled.p`
    color: white;
    font-size: 3vh;
    font-weight: 400;
`

const SmallText = styled.p`
    color: white;
    font-size: 2vh;
    font-weight: 200;
    margin-top: 15%;
`

const InputEmail = styled(Form.Control)`
    width: 50%;
    display: inline-block;
    color: white;
    background-color: rgba(0,0,0,0);
    &:focus{
        background-color: rgba(0,0,0,0);
        color: white;
        box-shadow: none;
        border-color: white;
    }
    &::placeholder{
        color: white;
    }
`

const ButtonSendEmail  = styled(Button)`
    background-color: rgba(0,0,0,0);
    border-color: white;
    width: 20%;
    margin-top: -5px;
    &:hover{
        color: rgb(127, 22, 26);
        background-color: white;
        border-color: rgb(127,22,26);
        transition: 0.5s;
    }
    &:focus{
        background-color: white;
        color: rgb(127, 22, 26);
        border-color: white;
        box-shadow: none;
    }
`

const StatsText = styled.p`
    color: white;
`

export const HomeMainPanel = () => {
    const [state, setState] = React.useState({
        emailText: "",
        statsText: ""
    })
    const handleEmailTextChange = (e) => {
        setState({...state, emailText: e.target.value})
    }
    const handleButtonSendEmailClick = async(e) => {
        e.preventDefault()

        if(state.emailText.includes('@') && state.emailText.includes('.')){
            await fetch(`https://us-central1-jzm-imoveis-a570d.cloudfunctions.net/sendMail?email=${state.emailText}`)
            setState({...state, emailText: "", statsText: "Entraremos em contato em breve!"})
        }else{
            setState({...state, emailText: "", statsText: "Email invalido!"})
        }
    }

    return(
        <StyledArea>
            <RedArea>
                <BigText className="ml-5">
                    RESIDENCIAL, COMERCIAL, INDUSTRIAL.
                </BigText>
                <MediumText className="mx-5 mt-5">                    
                    ALUGUE OU COMPRE IMÓVEIS COM SEGURANÇA E AGILIDADE.
                </MediumText>
            </RedArea>
            <SpacingDiv/>
            <PanelArea className="mx-5"/>
        </StyledArea>
    )
}
