import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BathtubIcon from '@material-ui/icons/Bathtub'
import HotelIcon from '@material-ui/icons/Hotel'
import CropFreeSharpIcon from '@material-ui/icons/CropFreeSharp'
import DriveEtaIcon from '@material-ui/icons/DriveEta'
import styled from 'styled-components'
import {useHistory} from 'react-router-dom'

const Card = styled.div`
    cursor: pointer;
    &:hover {
        transition: all .2s ease-in-out;
        transform: scale(1.02);
    }
`

const Imagem = styled.img`
    object-fit: cover;
    height: 250px;
`

const Questrial = styled.p`
    font-family: questrial, sans-serif;
`

const QuestrialSpan = styled.span`
    font-family: questrial, sans-serif;
`

export const CardImovel = ({imovel, tipo}) => {
    const history = useHistory()
    let preco
    if((tipo === "aluguel" && imovel.valor_aluguel) || (tipo === "ambos" && imovel.valor_aluguel && !imovel.valor_venda)){
        preco = <Questrial className="font-weight-bold mr-3">Valor aluguel: {parseFloat(imovel.valor_aluguel.toString()).toLocaleString('pt-BR', {style: 'currency',currency: 'BRL'})}</Questrial>
    }else if((tipo === "venda" && imovel.valor_venda) || (tipo === "ambos" && imovel.valor_venda && !imovel.valor_aluguel)){
        preco = <Questrial className="font-weight-bold mr-3">Valor venda: {parseFloat(imovel.valor_venda.toString()).toLocaleString('pt-BR', {style: 'currency',currency: 'BRL'})}</Questrial>
    }else if(tipo === "ambos" &&  imovel.valor_aluguel && imovel.valor_venda){
        preco = <>
                    <Questrial className="font-weight-bold mr-3">Valor aluguel: {parseFloat(imovel.valor_aluguel.toString()).toLocaleString('pt-BR', {style: 'currency',currency: 'BRL'})}</Questrial>
                    <Questrial className="font-weight-bold mr-3">Valor venda: {parseFloat(imovel.valor_venda.toString()).toLocaleString('pt-BR', {style: 'currency',currency: 'BRL'})}</Questrial>
                </>
    }else{
        return null
    }

    return (
        <Card className="shadow-sm w-100 bg-white rounded my-2" onClick={()=>history.push(`/imovel/${imovel.id}`)}>
            <Imagem className="w-100 rounded-top" src={imovel.capa}/>
            <Questrial className="mt-2 font-weight-bold text-center">{imovel.nome}</Questrial>
            {preco}
            <Row className="pb-2">
                <Col className="px-1 text-center">
                    <span><HotelIcon style={{"color":"#7F161A"}}/> <QuestrialSpan>{imovel.quartos}</QuestrialSpan></span>
                </Col>
                <Col className="px-1 text-center">
                    <span><BathtubIcon style={{"color":"#7F161A"}}/> <QuestrialSpan>{imovel.banheiros}</QuestrialSpan></span>
                </Col>
                <Col className="px-1 text-center">
                    <span><CropFreeSharpIcon style={{"color":"#7F161A"}}/><QuestrialSpan>{imovel.area}m²</QuestrialSpan></span>
                </Col>
                <Col className="px-1 text-center">
                    <span><DriveEtaIcon style={{"color":"#7F161A"}}/><QuestrialSpan> {imovel.vagas}</QuestrialSpan></span>
                </Col>
            </Row>
        </Card>)
}