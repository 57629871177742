import React, {useState} from 'react'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {fetchImoveis} from '../functions/fetchAPI'
import {CardImovel} from '../components/CardImovel'

const HomeAluguelImoveisArea = styled.div`
    background-color: #E5E5E5;
`

const AluguelDeImoveisTitle = styled.h2`
    color: #012942;
    font-family: questrial, sans-serif;
    font-weight: 700;
`

export const Aluguel = () => {
    const [imoveis, setImoveis] = useState([])

    React.useEffect(() => {
        fetchImoveis(setImoveis, "aluguel")
    }, [])

    return (
        <HomeAluguelImoveisArea className="py-5">
            <Container className="text-center">
                <AluguelDeImoveisTitle>Aluguel de Imóveis</AluguelDeImoveisTitle>
                <Row>
                {
                    imoveis.map((imovel, index) => {
                        if(imovel.capa && imovel.disponivel_aluguel){
                            return (
                                    <Col lg="4" key={imovel.id}>
                                        <CardImovel imovel={imovel} tipo="aluguel"/>
                                    </Col>
                            )
                        }
                    })
                } 
                </Row>
            </Container>
        </HomeAluguelImoveisArea>
    )
}